import {LOCALE} from "../config";
import {TRANSLATIONS} from "../constants/translations";

export default function translate(
  englishString: string,
  params?: Record<string, string | number>
): string {
  let translation =
    (TRANSLATIONS &&
      TRANSLATIONS[englishString] &&
      TRANSLATIONS[englishString][LOCALE]) ||
    englishString;

  // If params provided, replace any placeholders in the translation.
  if (params) {
    Object.keys(params).forEach(key => {
      // Replace all occurrences of {{key}} with the corresponding value.
      const value = params[key].toString();
      const regex = new RegExp(`{{\\s*${key}\\s*}}`, "g");
      translation = translation.replace(regex, value);
    });
  }

  return translation;
}
