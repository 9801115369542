import React, {useState, useEffect} from "react";
import {Script, ScriptStrategy} from "gatsby";
import invariant from "/src/utils/invariant";
import * as Amplitude from "/src/utils/amplitude";
import {logErrorToSentry} from "/src/utils/sentry";
import {
  TRACKING_CONFIG,
  AMPLITUDE_API_KEY,
  GTM_CONTAINER_ID,
  GATSBY_UNIFY_API_KEY,
} from "/src/config";

interface KetchConsent {
  purposes: {[key: string]: boolean};
  vendors?: string[];
  protocols?: {[key: string]: string};
}

invariant(
  AMPLITUDE_API_KEY != null,
  "GATSBY_AMPLITUDE_API_KEY must be defined in .env"
);
invariant(
  GTM_CONTAINER_ID != null,
  "GATSBY_GTM_CONTAINER_ID must be defined in .env"
);

function configureAmplitude(): void {
  try {
    Amplitude.init(AMPLITUDE_API_KEY!);
    Amplitude.updateUserProperties({});
  } catch (error) {
    logErrorToSentry(error as Error);
  }
}

const loadLinkedin = () => {
  return (
    <>
      <Script
        strategy="idle"
        id={TRACKING_CONFIG.LINKED_IN_FIRST.id}
        type="text/plain"
        data-purposes="behavioral_advertising"
      >
        {TRACKING_CONFIG.LINKED_IN_FIRST.script}
      </Script>

      <Script
        strategy="idle"
        id={TRACKING_CONFIG.LINKED_IN_SECOND.id}
        type="text/plain"
        data-purposes="behavioral_advertising"
      >
        {TRACKING_CONFIG.LINKED_IN_SECOND.script}
      </Script>

      <noscript>
        <img
          height="1"
          width="1"
          style={{display: "none"}}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=214812&fmt=gif"
        />
      </noscript>
    </>
  );
};

function TrackingTags() {
  /* A few notes:
   * Update(2020/10):
   *  Amplitude is blocked by great firewall in China so we turn on other marketing scripts
   *  for CN traffic to track events.
   *
   * Update(2021/07):
   *  We use pardot for tracking.
   *
   * Update:
   *  Our Google Tag Manager manages about 14 marketing tracking js plugins
   *  (Yahoo marketing, Linkedin Insight....) which are async loaded
   *  after Google Tag Manager script initialized. After users opt out tracking,
   *  we have to do a page reload to clean out all js tracking plugins.
   *
   * ================================================================
   * 1. Google Tag Manager technically recommends that you install
   *    a second script tag immediately after the `body` element.
   *    However, as far as I can tell this is only used for users
   *    who have javascript disabled. Since we need javascript to
   *    work in order to be GDPR compliant, we've intentionally
   *    omitted that second script tag.
   *
   * 2. Amplitude has a handy `setOptOut` method that tells amplitude
   *    not to track users when set. This is particularly helpful
   *    because it allows us to keep amplitude in our bundle even
   *    when it is disabled, which means we don't need to do a
   *    bunch of horrible `if (amplitude) {...}` checks everywhere.
   *
   * 3. Though Google's Analytics.js has a similar feature, it appears
   *    that Google Tag Manager does not. Also, Google doesn't provide
   *    an official NPM package - they still like the good old
   *    fashioned "script in the head" method. Therefore, we use
   *    Helmet to do just that.
   *
   * 4. Bizible doesn't appear to support programmatic opt-out.
   *
   * Update (2024/01):
   *  Ketch will be handling all script filtering, so all scripts should load by default.
   *
   * Update (2025/02/11):
   * - Dynamically switch the script type to "text/javascript" when Ketch consent for analytics is given,
   *   ensuring that tracking scripts (like GTM) load correctly on page refresh.
   * - Temporarily use "text/template" instead of "text/plain" to avoid duplicate loads, as ketch.js scans
   *   for that MIME type and conflicts have been observed with the current setup.
   * - This is a temporary solution until we can refactor the tracking logic.
   */

  if (!Amplitude.isInitialized()) {
    configureAmplitude();
  }

  const [scriptType, setScriptType] = useState("text/template");
  const [consentKey, setConsentKey] = useState(0);

  useEffect(() => {
    function consentPlugin(host: any) {
      // Receive only initial configuration get
      host.once("consent", ({purposes}: KetchConsent) => {
        const {analytics} = purposes;
        setScriptType(analytics ? "text/javascript" : "text/template");
        if (analytics) {
          setConsentKey(prev => prev + 1);
        }
      });
    }

    // @ts-ignore
    window.ketch("registerPlugin", consentPlugin);
  }, [scriptType]);

  // Using a key forces React to remount the scripts when consent changes
  const scriptProps = {
    type: scriptType,
    "data-purposes": "analytics",
    strategy: "idle" as ScriptStrategy,
    key: consentKey,
  };

  return (
    <>
      <Script
        {...scriptProps}
        nonce="{{nonce}}"
        key={`${TRACKING_CONFIG.GTM_DEFAULT.id}-${consentKey}`}
      >
        {TRACKING_CONFIG.GTM_DEFAULT.script}
      </Script>

      <Script
        {...scriptProps}
        key={`${TRACKING_CONFIG.PARDOT.id}-${consentKey}`}
      >
        {TRACKING_CONFIG.PARDOT.script}
      </Script>

      <Script
        {...scriptProps}
        key={`fp-third-party-pardot-${consentKey}`}
        src="https://pi.pardot.com/pd.js"
        onLoad={() => {
          Amplitude.updateUserProperties({});
        }}
      />

      <Script
        {...scriptProps}
        nonce="{{nonce}}"
        key={`${TRACKING_CONFIG.GROW_SURF.id}-${consentKey}`}
      >
        {TRACKING_CONFIG.GROW_SURF.script}
      </Script>

      <Script
        {...scriptProps}
        strategy="post-hydrate"
        data-api-key={`${GATSBY_UNIFY_API_KEY}`}
        key={`unifytag-${consentKey}`}
        src="https://cdn.unifygtm.com/tag/v1/unify-tag-script.js"
        nonce="{{nonce}}"
      />

      {loadLinkedin()}
    </>
  );
}

export default TrackingTags;
